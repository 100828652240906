import { DegreeToRadians } from "../common/Utils";
import {
  Group,
  BufferGeometry, 
  Mesh,
  CanvasTexture, 
  Vector2, 
  IcosahedronBufferGeometry, 
  MeshBasicMaterial,
  AdditiveBlending,
  InstancedMesh,
  DynamicDrawUsage,
  Object3D,
  Vector3,
  MeshLambertMaterial,
  Clock
} from "three";

import {MeshLine,MeshLineMaterial} from './lib/meshline.js';
import NeurocureWorld from "./NeurocureWorld";

class LineController{

  radius:number = .05
  minRadius:number = .025
  positions:{x:number,y:number,z:number}[] = []
  p:any
  group: any

  connections:{
    startPoint: { x: number; y: number; z: number; }; 
    connections: { x: number; y: number; z: number; }[];
  }[]
  

  lines:any
  spheres:any
  dummy:Object3D = new Object3D();

  alphaMapCanvas:any
  alphaMapContext:any
  alphaTexture: CanvasTexture | null = null

  randomPointSeed:any = [];

  world: NeurocureWorld

  constructor(model:Mesh,world:NeurocureWorld){
    this.world = world;
    this.positions = this.mergePointsFromBufferGeometry(model.geometry.attributes.position.array);
    
    this.CreateAlphaTexture();
    this.connections = this.positions.map((point,i)=>{

      return {
        startPoint : point,
        connections : this.positions.filter((p,j)=>{
            if(p === point){return false;}
            return this.pointInCircle(point,p, this.minRadius + Math.random() * this.radius)[0];
          })
      }
    })


    this.setupLines();

    this.SetupMovingDots(this.connections.map((points)=>{return points.startPoint}));
  }

  pointInCircle(point:any,target:any, radius:any){
    const distsq = (point.x - target.x) * (point.x - target.x) + (point.y - target.y) * (point.y - target.y) + (point.z - target.z) * (point.z - target.z);
    // returns bool , distance to target origin 
    return [distsq <= radius * radius * radius,distsq];
  }

  CreateAlphaTexture = () =>{
    this.alphaMapCanvas = document.createElement("canvas");
    this.alphaMapContext = this.alphaMapCanvas.getContext("2d");

    document.body.appendChild(this.alphaMapCanvas);
    this.alphaMapCanvas.height = 128;
    this.alphaMapCanvas.width = 128;
    this.alphaMapCanvas.style.display = "none";
    this.alphaMapCanvas.style.position = "absolute";
    this.alphaMapCanvas.style.top = 0;
    this.alphaMapCanvas.style.left = 0;
    this.alphaMapCanvas.style.width = 128;
    this.alphaMapCanvas.style.height = 128;

    // Create gradient
      const grd = this.alphaMapContext.createLinearGradient(0, 0,0,128);
      grd.addColorStop(.1, "black");
      grd.addColorStop(.5, "white");
      grd.addColorStop(.9, "black");

      // Fill with gradient
    this.alphaMapContext.fillStyle = grd;
    this.alphaMapContext.fillRect(0, 0, 128, 128);

    this.alphaTexture = new CanvasTexture(this.alphaMapCanvas);

  }

  mergePointsFromBufferGeometry = (points:any) =>{
    const positions = [];
    const geometryVerts = points;

    for(let i=0;i<geometryVerts.length;i+=9 ){
      positions.push({
        x : (geometryVerts[i + 0] +geometryVerts[i + 3] +geometryVerts[i + 6]) / 3,
        y : (geometryVerts[i + 1] +geometryVerts[i + 4] +geometryVerts[i + 7]) / 3,
        z : (geometryVerts[i + 2] +geometryVerts[i + 5] +geometryVerts[i + 8]) / 3
      })
    }
    
    for(let i=0;i<Math.floor(geometryVerts.length / 2);i+=9 ){
      positions.push({
        x : (geometryVerts[i + 0] +geometryVerts[i + 3] +geometryVerts[i + 6]) / 3,
        y : (geometryVerts[i + 1] +geometryVerts[i + 4] +geometryVerts[i + 7]) / 3,
        z : ((geometryVerts[i + 2] +geometryVerts[i + 5] +geometryVerts[i + 8]) / 3) - .5
      })
    }

    return positions;
  }

  setupLines = () => {    

    const completeLine = new MeshLine();
    let lineConnections = this.connections.map((connection, index)=>{
    
      if(index % 50 != 0){return; }

        const p:any = []
        connection.connections.map((point, j)=>{
            p.push(connection.startPoint.x,connection.startPoint.y,connection.startPoint.z);
            p.push(point.x,point.y,point.z);
          
        });

        return p;
      
    });

    lineConnections = lineConnections.filter((connection)=>{return typeof(connection) !== "undefined"});

    completeLine.setPoints(lineConnections.flat(),(p:any) => 2 + Math.sin(50 * p));

    this.lines = new Mesh( completeLine, new MeshLineMaterial({
      repeat : new Vector2(1,1),
      opacity : .1,
      //blending: AdditiveBlending,
      //alphaTest : .05,
      depthTest: false,
      transparent : true,
      lineWidth: 30,
      color: 0xffffff,
      resolution : new Vector2(32,32),// this.xr.Renderer.size
      //dashArray: 0.05,
      //dashRatio:0.95,
    }));

    this.lines.scale.set(20000,20000,20000);
    //this.lines.rotateY(Math.PI)
    this.lines.rotation.set(DegreeToRadians(40), DegreeToRadians(180), 0);
    this.world.sceneController.AddToScene(this.lines);
    
  }
  Remove(){
    this.world.sceneController.RemoveFromScene(this.lines);
    this.world.sceneController.RemoveFromScene(this.dummy);
    this.world.sceneController.RemoveFromScene(this.group);
  }


  SetupMovingDots(points:any){
  
    this.p = []
    
    points.filter((p:any,index:number)=>index % 3 == 0).map((po:any,i:number)=>{
      
        this.randomPointSeed.push({
          seed : Math.random() * .01,
          progress : Math.random(),
          dir : Math.random() <= .5 ? 1 : -1,
          speed : .4,
          size : .1 + Math.random()
        });
        this.p.push(po)
      

    });

    this.group = new Group();
    const geometry = new BufferGeometry().setFromPoints(this.p);
          geometry.computeVertexNormals();
          //geometry.scale( 100,100,100 );
    const icoSphere = new IcosahedronBufferGeometry(.002,1);
    this.spheres = new InstancedMesh( icoSphere, new MeshLambertMaterial({
      color:0xffffff,
      emissive:0xffffff,
      // opacity:.8,
      // transparent: true,
      blending:AdditiveBlending,
      // depthTest: false,
      emissiveIntensity : 5
    }), points.length);
    this.spheres.instanceMatrix.setUsage( DynamicDrawUsage ); // will be updated every frame
    
    this.group.add(this.spheres);
    //this.dummy.position.set(scene.children[0].position);
    this.world.sceneController.AddToScene( this.dummy );
    this.world.sceneController.AddToScene( this.group );
    


    this.group.scale.set(20000,20000,20000);
    this.group.rotation.set(DegreeToRadians(40), DegreeToRadians(180), 0);

   
    this.world.animationLoop.AddAnimationLoop(this.Animate);

  }

  RemoveAnimationLoop = () => {
    this.world.animationLoop.RemoveAnimationLoop(this.Animate);
  }

  Animate = (time:number, clock:Clock) => {

      for(let i=1;i<this.p.length;i++){

        this.randomPointSeed[i].dir = this.randomPointSeed[i].progress > 1 ? this.randomPointSeed[i].dir * -1 : this.randomPointSeed[i].dir; 
        this.randomPointSeed[i].dir = this.randomPointSeed[i].progress < 0 ? this.randomPointSeed[i].dir * -1 : this.randomPointSeed[i].dir; 

        this.randomPointSeed[i].progress = this.randomPointSeed[i].progress + (this.randomPointSeed[i].seed * this.randomPointSeed[i].dir * this.randomPointSeed[i].speed );

        this.dummy.position.copy(new Vector3()).lerpVectors(this.p[i - 1], this.p[i] , this.randomPointSeed[i].progress );
        this.dummy.scale.copy(new Vector3(this.randomPointSeed[i].size,this.randomPointSeed[i].size,this.randomPointSeed[i].size));

        this.dummy.updateMatrix();
        this.spheres.setMatrixAt( i ++, this.dummy.matrix );
      }
      this.spheres.instanceMatrix.needsUpdate = true;

      
  }

}

export default LineController;