import { Vector3 } from "three";

const FresnelShaderAlpha = {

	uniforms: {
    offsetPosition: {
      value : new Vector3(0,0,1),
      type: "vec3"
    },
    opacityMultiplier: {
      value : .1,
      type: "float"
    }
		// customOpacity : {
		// 	value : 1.0,
		// 	type: "f"
		// },
		// scale : {
		// 	value : 1.0,
		// 	type: "f"
		// },
		// noiseTexture: { 
		// 	type: "t", 
		// 	value: null 
		// },
		// time: { 
		// 	type: "f", 
		// 	value: 0.0 
		// },
	},

	vertexShader: [
	
		"varying vec3 vPositionW;",
		"varying vec3 vNormalW;",
    "uniform vec3 offsetPosition;",
		"void main() {",

		"	vPositionW = vec3( vec4( position, 1.0 ) * modelMatrix);",
		" vNormalW = normalize( vec3( vec4( normal, 0.0 ) * modelMatrix ) );",

		"	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}"

	].join( "\n" ),

	fragmentShader: [
		"varying vec3 vPositionW;",
		"varying vec3 vNormalW;",
		"uniform vec3 offsetPosition;",
		"uniform float opacityMultiplier;",

		"void main() {",
		
		"	vec3 color = vec3(1., 1., 1.);",
		"	vec3 viewDirectionW = normalize(cameraPosition - vPositionW);",
		"	float fresnelTerm = dot(viewDirectionW + offsetPosition, vNormalW);",
		"	fresnelTerm = clamp(1.0 - fresnelTerm, 0., 1.);",
		"	gl_FragColor = vec4( color * fresnelTerm, fresnelTerm * opacityMultiplier);",

		"}"

	].join( "\n" )

};

export default FresnelShaderAlpha;