import { moduleExpression } from '@babel/types';
import { CanvasTexture, Color, Object3D, Scene } from 'three'
import ISceneController from './ISceneController'
import { Easing, Tween } from "@tweenjs/tween.js";
import { rgbToHex, hexToRgb } from '@/common/Utils';

export default class IR_SceneController implements ISceneController {
  readonly currentScene = new Scene()
  readonly scenes: { [key: string]: Scene; } = {
    default: this.currentScene
  }

  c: any
  backgroundTexture: CanvasTexture


  constructor() {
    this.currentScene.name = "default";

    this.backgroundTexture = new CanvasTexture(this.GetCanvas());

    this.currentScene.background = this.backgroundTexture;
  }

  GetActiveScene() {
    return this.currentScene;
  }

  AddToScene(model: Object3D, sceneName: string = "default", createIfNotExist: boolean = false): Object3D {

    if (!Object.prototype.hasOwnProperty.call(this.scenes, sceneName) && !createIfNotExist) {
      throw `IR_SceneController => doesn't have a Scene with name: ${sceneName}.`;
    } else if (!Object.prototype.hasOwnProperty.call(this.scenes, sceneName) && createIfNotExist) {
      this.scenes[sceneName] = new Scene();
      this.scenes[sceneName].name = sceneName;

      this.scenes[sceneName].background = this.backgroundTexture;
    }

    this.scenes[sceneName].add(model);

    return model;
  }
  GetCanvas() {
    const canvas = document.createElement("canvas");
    canvas.width = 512;
    canvas.style.width = "512px";
    canvas.height = 512;
    canvas.style.height = "512px";
    canvas.style.position = "fixed";
    canvas.style.display = "none";

    document.body.appendChild(canvas);

    this.c = canvas.getContext("2d");

    this.c.fillRect(0, 0, 512, 512);

    return canvas;
  }

  UpdateCanvas(fn: ((that: IR_SceneController) => void) | null) {
    fn ? fn(this) : null;
  }

  RemoveFromScene(model: Object3D, sceneName?: string) {
    if (typeof (sceneName) == "undefined") {
      sceneName = "default";
    }
    if (!Object.prototype.hasOwnProperty.call(this.scenes, sceneName)) { console.warn(`WorldSceneController doesn't have a Scene with name: ${sceneName}.`); return false; }

    this.scenes[sceneName].remove(model);
  }




  AnimateBackground(name: string, transitionTime = 0) {

    const grd = this.c.createLinearGradient(0, 0, 0, 512);

    switch (name) {
      case 'LandingPage':
        grd.addColorStop(0, "#000000");
        grd.addColorStop(1, "#111111");
        this.SetGrd(grd);
        break;
      case 'MainPage':
        //console.log("START BACKGROUDN ANIM ")
        this.StartBackgroundTransition({ 0: "#000000", 1: "#111111" }, { 0: "#222222", 1: "#222222" }, transitionTime)
        break;
    }

  }

  StartBackgroundTransition(oldGrd: any, newGrd: any, transitionTime: number) {
    const oldRGBGrd0 = hexToRgb(oldGrd[0])
    const oldRGBGrd1 = hexToRgb(oldGrd[1])
    const oldRGBGrd = { r0: oldRGBGrd0.r, g0: oldRGBGrd0.g, b0: oldRGBGrd0.b, r1: oldRGBGrd1.r, g1: oldRGBGrd1.g, b1: oldRGBGrd1.b };
    
    const newRGBGrd0 = hexToRgb(newGrd[0])
    const newRGBGrd1 = hexToRgb(newGrd[1])
    const newRGBGrd = { r0: newRGBGrd0.r, g0: newRGBGrd0.g, b0: newRGBGrd0.b, r1: newRGBGrd1.r, g1: newRGBGrd1.g, b1: newRGBGrd1.b };

    const tween = new Tween(oldRGBGrd)
      .to(
        newRGBGrd
        , 5000)
      .easing(Easing.Quadratic.InOut)
      .onUpdate((v) => {
        const grd = this.c.createLinearGradient(0, 0, 0, 512);
        grd.addColorStop(0, rgbToHex(Math.round(v.r0), Math.round(v.g0), Math.round(v.b0)));
        grd.addColorStop(1, rgbToHex(Math.round(v.r1), Math.round(v.g1), Math.round(v.b1)));
        this.SetGrd(grd)
      }).onComplete(() => {

      });
    tween.start();
  }

 
  SetGrd(grd: any) {
    // Fill with gradient
    this.c.fillStyle = grd;
    this.c.fillRect(0, 0, 512, 512);

    this.backgroundTexture.needsUpdate = true;
  }
}


export { IR_SceneController }