
import { useStore } from "vuex";
import Loading from "./components/Loading.vue";
import DevControls from './partials/DevControls.vue';
import CameraOverlay from "./partials/CameraOverlay.vue"
import NeurocureWorld from './Scene/NeurocureWorld';
import YouTubeFullscreen from './partials/YouTubeFullscreen.vue'

import { useRoute } from "vue-router";
import { computed } from "@vue/reactivity";

import Cookies from './partials/Cookies.vue'

import config from '../main.config';

export default {
  
  components: { Loading, DevControls,CameraOverlay ,Cookies,YouTubeFullscreen},

  setup(){
    const route = useRoute()
    const store = useStore();


    const currentRoute = computed(() => {
      //console.log(route.name,store.state.showClouds);

      //if(store.state.showClouds){return "MainPage";}
      return store.state.backgroundChange ? "MainPage" : route.name;
    })
  
    return { currentRoute, config };
  },
  mounted(){
    const store = useStore();
    const world = new NeurocureWorld("scene-background");
    store.commit("SetWorld", world);
  }
}

