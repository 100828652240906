import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { GradientShader } from './Shader/GradientShader';
import { IPass } from "./IPass";
import IWorld from "../World/IWorld";

class GradientEffect implements IPass{
  composer: EffectComposer
  pass : ShaderPass
  dpr: number = window.devicePixelRatio ? window.devicePixelRatio : 1

  constructor(composer:EffectComposer, world: IWorld){
    this.composer = composer;
    this.pass = new ShaderPass( GradientShader );

    this.composer.addPass( this.pass );
		
    this.Resize();
  }

  Resize = ()=>{
    //this.pass.uniforms['resolution'].value.set(1 / (window.innerWidth * this.dpr), 1 / (window.innerHeight * this.dpr));
  }
  
}

export {GradientEffect}
