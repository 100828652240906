import { Color, Uniform , Vector2} from 'three';
import glslify from 'glslify';
import modes from 'glsl-blend/modes';

import * as blendMode from '!raw-loader!glslify-loader!glsl-blend/all.glsl';


//const src = glslify.file('glsl-blend/screen');

const GradientShader = {

	uniforms: {

		'tDiffuse': { value: null },
		'opacity': { value: 1.0 },
		'blendOpacity': { value: 0.8 },
		'gradientColor_1': { value: new Color(0xffffff) },
		'gradientColor_2': { value: new Color(0xb4daff) },
		'blendModeIndex': { value: 17 },

	},

	vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader:
  `
    ${blendMode.default}
		precision highp float;
    

		uniform sampler2D tDiffuse;
		uniform float opacity;
		uniform float blendOpacity;
		uniform vec3 gradientColor_1;
		uniform vec3 gradientColor_2;
		uniform int blendModeIndex;

		
		varying vec2 vUv;

		void main() {
			gl_FragColor = texture2D(tDiffuse, vUv);

      vec3 gradient = mix(gradientColor_2, gradientColor_1, pow(vUv.y,0.5));

      gl_FragColor.rgb *=  mix( vec3(1.0) , gradient , opacity); 

      gl_FragColor.rgb = blendMode(blendModeIndex, gl_FragColor.rgb, gradient, blendOpacity);
		}`

};

export { GradientShader };