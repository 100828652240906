import { Easing, Tween } from "@tweenjs/tween.js";
import { Vector3 } from "three";
import { lerp } from "three/src/math/MathUtils";
import { useStore } from "vuex";
import IControls from "./Setup/Controls/IControls";

type Transform = {
  Position: Vector3,
  Target: Vector3
}

export default class CameraTransition {
  controls: IControls
  store: any

  dev:any = {
    updatePost : true
  }

  positionLerp = new Vector3()
  targetLerp = new Vector3()

  constructor(camera: IControls) {
    this.controls = camera;
    this.store = useStore();
  }

  ExtractTransformsFromStage(id:number){
    let searchedStage = {
      Position: new Vector3(),
      Target: new Vector3()
    };

    this.store.state.stages.map((stage: any) => {
      const filteredContentBlock = stage.content_blocks.find((contentBlock: any) => contentBlock.id == id);
      if (typeof (filteredContentBlock) != "undefined") {
        searchedStage = {
          Position: new Vector3(filteredContentBlock.Position.x, filteredContentBlock.Position.y, filteredContentBlock.Position.z),
          Target: new Vector3(filteredContentBlock.Target.x, filteredContentBlock.Target.y, filteredContentBlock.Target.z)
        };
      }
    });

    return searchedStage;
  }

  LerpCameraPosition(fromStage: { Position: Vector3, Target: Vector3, PostProcessingSettings?: any }, toStage: { Position: Vector3, Target: Vector3, PostProcessingSettings?: any }, alpha: number) {
    //console.log("Lerp Camera Psoitons", alpha)
    
    if(this.dev.updatePost){
      this.UpdatePostProcessingValues(fromStage, toStage,alpha);
    }
    
    this.positionLerp.lerpVectors(fromStage.Position, toStage.Position, alpha);
    this.targetLerp.lerpVectors(fromStage.Target, toStage.Target, alpha);

    this.controls.SetPosition(this.positionLerp);
    this.controls.SetTarget(this.targetLerp);
  }


  UpdatePostProcessingValues(fromStage: { PostProcessingSettings?: any }, toStage: {PostProcessingSettings?: any }, alpha: number){
    //console.log("CHANGE CONTENT BLOCK ", fromStage.PostProcessingSettings.DepthOfField, toStage.PostProcessingSettings.DepthOfField)
    const fromDepthOfField = fromStage.PostProcessingSettings.DepthOfField;
    const toDepthOfField = toStage.PostProcessingSettings.DepthOfField;

    const fromSmear = fromStage.PostProcessingSettings.Smear;
    const toSmear = toStage.PostProcessingSettings.Smear;

    /*this.store.state.world.bokehEffect.pass.uniforms.focus.value = this.SetPostProcessingValues(fromDepthOfField.Focus, toDepthOfField.Focus, 1, alpha);
    this.store.state.world.bokehEffect.pass.uniforms.aperture.value = this.SetPostProcessingValues(fromDepthOfField.Aperture, toDepthOfField.Aperture, 0.025, alpha);
    this.store.state.world.bokehEffect.pass.uniforms.maxblur.value = this.SetPostProcessingValues(fromDepthOfField.Maxblur, toDepthOfField.Maxblur, 0, alpha);
*/
    const multiplier = Math.sin(alpha*Math.PI)
    
    this.store.state.world.blendPass.uniforms.mixRatio.value = this.SetPostProcessingValues(fromSmear, toSmear, 0, alpha,multiplier);


    this.store.state.world.bloomEffect.pass.threshold = lerp(fromStage.PostProcessingSettings.bloom.threshold, toStage.PostProcessingSettings.bloom.threshold, alpha);
    this.store.state.world.bloomEffect.pass.strength = lerp(fromStage.PostProcessingSettings.bloom.strength, toStage.PostProcessingSettings.bloom.strength, alpha);
    this.store.state.world.bloomEffect.pass.radius = lerp(fromStage.PostProcessingSettings.bloom.radius, toStage.PostProcessingSettings.bloom.radius, alpha);

  }

  SetPostProcessingValues(from:any, to:any, defaultValue:number, alpha:number, multiplier=1){
    if(from == undefined){
      from  = defaultValue;
    }
    const returnValue = (multiplier*lerp(from, to, alpha))

    return returnValue
  }


  StartAnimation(): Promise<void> {

    this.store.commit("SetStartTransition", true);

    return new Promise((resolve, reject) => {

      const start_position = this.controls.GetPosition();
      const start_target = this.controls.GetTarget();
      const Pos = new Vector3();
      const Target = new Vector3();
      const coords = { 
        lerp:0,
        posX : start_position.x,
        posY : start_position.y,
        posZ : start_position.z,
        targetX : start_target.x,
        targetY : start_target.y,
        targetZ : start_target.z,
        
      } 

      /**
       * {
          lerp:1,
          posX : -0.02339135433474,
          posY : 1.6281982051590054,
          posZ : -16.591472618793106,
          targetX : 0,
          targetY : 0,
          targetZ : 0,
        }
       */

      const tween = new Tween(coords)
        .to({
          lerp:1,
          posX : 1.2410479068327191,
          posY : 3.033517869863638,
          posZ : -6.153248291344106,
          targetX :-1.7468267429321997,
          targetY : 1.9146718908962894,
          targetZ : -0.18399945062431633,
          smear: 0
        }, 6500)
        .easing(Easing.Quadratic.InOut)
        .onUpdate((v) => {


          Pos.set(v.posX,v.posY,v.posZ);
          Target.set(v.targetX,v.targetY,v.targetZ);
          this.controls.SetPosition(Pos);
          this.controls.SetTarget(Target);

          this.store.state.world.lineController.randomPointSeed.map((p:any)=>{
            p.speed *= .99;
          });
          
          let value = (Math.cos(Math.pow(v.lerp, 2) * (Math.PI * .5)) * .92);
          value = Math.max(0, Math.min( .92 , value  ));
          
          this.store.state.world.blendPass.uniforms.mixRatio.value = value;
          
          this.store.commit("SetStartAnimationLerp" , v.lerp );
        }).onComplete(()=>{
          resolve();
        });



      tween.start();
    });
  }
}