import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { PerspectiveCamera} from 'three';
import  {degToRad} from 'three/src/math/MathUtils';

class DistortionEffect{
  
  composer:EffectComposer
  effect
  camera:PerspectiveCamera

  horizontalFOV:number = 75

  shaderDefinition = {
				
    uniforms: {
      "tDiffuse": 		{ type: "t", value: null },
      "strength": 		{ type: "f", value: 0 },
      "height": 			{ type: "f", value: 1 },
      "aspectRatio":		{ type: "f", value: 1 },
      "cylindricalRatio": { type: "f", value: 1 }
    },

    vertexShader: [
      "uniform float strength;",          // s: 0 = perspective, 1 = stereographic
      "uniform float height;",            // h: tan(verticalFOVInRadians / 2)
      "uniform float aspectRatio;",       // a: screenWidth / screenHeight
      "uniform float cylindricalRatio;",  // c: cylindrical distortion ratio. 1 = spherical
       
      "varying vec3 vUV;",                // output to interpolate over screen
      "varying vec2 vUVDot;",             // output to interpolate over screen
       
      "void main() {",
        "gl_Position = projectionMatrix * (modelViewMatrix * vec4(position, 1.0));",
       
        "float scaledHeight = strength * height;",
        "float cylAspectRatio = aspectRatio * cylindricalRatio;",
        "float aspectDiagSq = aspectRatio * aspectRatio + 1.0;",
        "float diagSq = scaledHeight * scaledHeight * aspectDiagSq;",
        "vec2 signedUV = (2.0 * uv + vec2(-1.0, -1.0));",
       
        "float z = 0.5 * sqrt(diagSq + 1.0) + 0.5;",
        "float ny = (z - 1.0) / (cylAspectRatio * cylAspectRatio + 1.0);",
       
        "vUVDot = sqrt(ny) * vec2(cylAspectRatio, 1.0) * signedUV;",
        "vUV = vec3(0.5, 0.5, 1.0) * z + vec3(-0.5, -0.5, 0.0);",
        "vUV.xy += uv;",
      "}"
    ].join("\n"),
    
    fragmentShader: [
      "uniform sampler2D tDiffuse;",      // sampler of rendered scene�s render target
      "varying vec3 vUV;",                // interpolated vertex output data
      "varying vec2 vUVDot;",             // interpolated vertex output data

      "void main() {",
        "vec3 uv = dot(vUVDot, vUVDot) * vec3(-0.5, -0.5, -1.0) + vUV;",
        "gl_FragColor = texture2DProj(tDiffuse, uv);",
      "}"
    ].join("\n")
  }

  constructor(composer:EffectComposer, camera: PerspectiveCamera){

    this.composer = composer;
    this.camera = camera;
  
    this.effect = new ShaderPass( this.shaderDefinition );
		this.composer.addPass( this.effect );
		


    this.SetDistortion(this.horizontalFOV);
  }

  SetDistortion(fov:number){

    const height = Math.tan( degToRad(fov) / 2) / this.camera.aspect;

		this.camera.fov = Math.atan(height) * 2 * 180 / 3.1415926535;
		this.camera.updateProjectionMatrix();
  }
}

export { DistortionEffect }