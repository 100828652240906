import { Clock, WebGLRenderer, XRAnimationLoopCallback, XRFrame } from "three";
import IRenderer from "../Renderer/IRenderer";
import IAnimationLoop from "./IAnimationLoop";
import AnimationCallbacks from "./TAnimationCallback";

export default class IR_AnimationLoop implements IAnimationLoop {

  renderer: IRenderer
  callbacks: AnimationCallbacks[] = []
  clock: Clock = new Clock()

  constructor(renderer: IRenderer) {
    this.renderer = renderer;
    this.renderer.instance.setAnimationLoop(this.OnAnimationLoop);

  }

  OnAnimationLoop = (time: number, frame?: XRFrame | undefined) => {

    this.callbacks.map((cb) => {
      cb(time, this.clock, frame);
    });

  }

  AddAnimationLoop(fn: (time: number, clock: Clock, frame?: XRFrame | undefined) => void | null) {
    if (fn != null) {
      this.callbacks.push(fn);
    }
  }

  RemoveAnimationLoop(fn: (time: number, clock: Clock, frame?: XRFrame | undefined) => void | null) {

    //console.log("this.RemoveAnimationLoop");

    const index = this.callbacks.indexOf(fn);
    if (index != -1) {
      this.callbacks.splice(index, 1);
    }

  }



}