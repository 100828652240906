import { DoubleSide, Group, Mesh, MeshBasicMaterial, MeshNormalMaterial, PlaneBufferGeometry } from "three";
import { useStore } from "vuex"
import Atmosphere from "./Atmosphere";
import NeurocureWorld from "./NeurocureWorld";
import IR_SceneController from "./Setup/SceneController/IR_SceneController";
import Stage from "./Stage";

class StageController {
  store:any
  library:any
  stages:any = {}

  constructor(){
    this.store = useStore();
  }

  Setup(library:any){
    this.library = library;
    return new Promise((resolve, reject)=>{

        //Landing
        this.stages.intro = new Stage(0, this.store.state.world , this.store, null, 1000, library.brain_landing.gltf.scene);
        this.stages.intro.Setup((that:any) => {
          that.instance.position.set(0, 0, 0);
          that.instance.rotation.set(this.DegreeToRadians(40), this.DegreeToRadians(180), 0);
          that.instance.scale.set(20000, 20000, 20000);
          //that.instance.rotateY(Math.PI);
          this.store.state.world.postProcessingController.AnimatePostProcessSettings("Landing",0);
        });

        this.stages.intro.OnChangeStageVisiblity = (boolean:boolean) => {
          if (!boolean) {    
            this.store.commit("SetGlobalBackground", true);
            this.store.state.world.sceneController.AnimateBackground("MainPage",2);
            this.store.state.world.postProcessingController.AnimatePostProcessSettings("MainPage",1);

            setTimeout(()=>{
              if(this.store.state.world.lineController != null){
                this.store.state.world.lineController.Remove();
              }
            },1000);
            
          }
        }


        //Intro
        this.stages.entry = new Stage(0, this.store.state.world, this.store, null, 30, library.earth.gltf.scene);
        this.stages.entry.Setup((that:any) => {
          that.instance.frustumCulled = false;
          that.instance.children.map((child: any) => {
            child.frustumCulled = false;
          });

          that.instance.position.set(0, -35, 0);

          that.instance.rotation.set(this.DegreeToRadians(42), this.DegreeToRadians(90), 0);

          const plane = new Mesh(new PlaneBufferGeometry(200,200,32,32), new MeshBasicMaterial({
            side:DoubleSide,
            transparent : true,
            map : (new Atmosphere()).texture
          }));
          that.instance.add(plane);
          plane.lookAt(this.store.state.world.camera.GetPosition())

        });
        this.stages.entry.OnChangeStageVisiblity = (boolean:boolean) => {
          if (!boolean) {
            this.store.commit("SetClouds", true);
          }
        }
        this.stages.entry.OnEnter();

        
        this.stages.entry.OnExitStage = () => {
          this.store.state.world.lineController.RemoveAnimationLoop();

        }


        //About
        this.stages.about = new Stage(1, this.store.state.world, this.store, 30, 0.03, library.campus.gltf.scene);
        this.stages.about.Setup((that:any) => {
          //that.instance.rotation.x = -90 * Math.PI / 180;
        });

        //Million
        this.stages.million = new Stage(2, this.store.state.world, this.store, 0.03, 0.006, library.brain_complete.gltf.scene);
        this.stages.million.Setup((that:any) => {});

        //Thousand
        this.stages.thousand = new Stage(3, this.store.state.world, this.store, 0.008, 0, library.neuron_complete.gltf.scene);
        this.stages.thousand.Setup((that:any) => {
          this.store.state.world.sceneController.RemoveFromScene(that.instance);
          const neurogroup = new Group();

          library.cluster_neuronen.gltf.scene.children[0].children.forEach((neuron:any) => {
            const inst = that.instance.clone();

              inst.position.set(neuron.position.x,neuron.position.y,neuron.position.z);
              inst.rotation.set(neuron.rotation.x,neuron.rotation.y,neuron.rotation.z)
              neurogroup.add(inst);
          });

          /*const count = 5;
          const abstand = {
            x: 0.0005,
            z: 0.0005
          }

          for (let z = 0; z < count; z++) {
            for (let x = 0; x < count; x++) {
              const inst = that.instance.clone();
              inst.position.x -= (abstand.x * count) / 2;
              inst.position.z -= (abstand.z * count) / 2;
              inst.position.x += abstand.x * x;
              inst.position.x += abstand.x * x;

              inst.position.z += abstand.z * z;
              inst.position.z += abstand.z * z;

              inst.rotateY((360 * Math.random()) * Math.PI / 180);
              neurogroup.add(inst);
            }
          }
          */
          neurogroup.visible = true;
          this.store.state.world.sceneController.AddToScene(neurogroup);

        });


        //One
        this.stages.one = new Stage(4, this.store.state.world , this.store, 0.008, 0, library.neuron_offen_lowpoly.gltf.scene);
        this.stages.one.Setup((that:any) => {
          //that.instance.position.set(0, 0, 500);
        })

        this.stages.one.Setup((that:any) => {
          //console.log("this Stage one", that);
          that.instance ? that.instance.rotation.y = Math.PI : null;
        });
        
        setTimeout(()=>{
          resolve(null);
        },250);
    })

  }

  DegreeToRadians(deg: number) {
    return deg * Math.PI / 180;
  }

}

export default StageController;