import { CanvasTexture } from "three";

class Atmosphere{
  canvas
  texture
  constructor(){
    this.canvas = this.CreateCanvas();

    this.texture = new CanvasTexture(this.canvas);

  }

  CreateCanvas(){
    const canvasElement = document.createElement("canvas");
    canvasElement.style.width = 512 + "px";
    canvasElement.style.height = 512 + "px";
    canvasElement.width = 512;
    canvasElement.height = 512;

    const c = canvasElement.getContext("2d");
    if(c){
      
      const grad = c.createRadialGradient(256, 256, 0, 256, 256, 256);
  
      grad.addColorStop(0, 'rgba(255, 255, 255, 1)');
      grad.addColorStop(0.46, 'rgba(255, 255, 255, 0.9)');
      grad.addColorStop(0.73, 'rgba(99, 205, 255, 0)');

      c.fillStyle = grad;
      c.fillRect(0, 0, 512, 512);
    }

    return canvasElement;
  }
}

export default Atmosphere;