import { Color, ShaderMaterial, MeshStandardMaterial,Vector3,MeshBasicMaterial } from "three";
import FresnelShaderAlpha from "./Setup/Shader/THREE.FresnelShaderAlpha";
import FresnelShaderColor from "./Setup/Shader/THREE.FresnelShaderColor";

const MaterialLibrary = {
  brain_landing : new ShaderMaterial({
    opacity: 1,
    uniforms: {
      offsetPosition: {
        value: new Vector3(0, 0, 1)
      },
      opacityMultiplier: {
        value: .4
      }
    },
    vertexShader: FresnelShaderAlpha.vertexShader,
    fragmentShader: FresnelShaderAlpha.fragmentShader,
    transparent: true,
    depthWrite: false,
    depthTest: false,
    //blending : THREE.AdditiveBlending,
  })


}

export default MaterialLibrary;