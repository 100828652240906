
import { SphereGeometry, Clock, Group, Mesh, MeshBasicMaterial, Object3D, Vector3, XRAnimationLoopCallback, XRFrame } from "three";
import IWorld from "./Setup/World/IWorld";
import { watch } from "vue";
export default class Stage {

  id: number
  instance: Group
  origin: Vector3
  activationDistance: number
  deactivationDistance: number
  store: any
  isActiveScene: boolean = false;

  OnEnterStage:()=>void = ()=>{}
  OnExitStage:()=>void = ()=>{}

  constructor(id: number, world: IWorld, store: any, activationDistance?: number | null, deactivationDistance?: number | null, mesh?: Object3D,) {

    this.id = id;
    this.instance = new Group();
    this.origin = new Vector3(0, 0, 0);
    this.activationDistance = 100000;
    this.deactivationDistance = 0;
    this.store = store;

    //const box = new Mesh(new BoxGeometry(), new MeshNormalMaterial());
    if (mesh != null) {
      this.instance.add(mesh);
    }

    if (activationDistance != null && activationDistance != undefined) {
      this.activationDistance = activationDistance
    }
    if (deactivationDistance != null && deactivationDistance != undefined) {
      this.deactivationDistance = deactivationDistance
    }


    const geometry = new SphereGeometry(this.activationDistance, 32, 16);
    const material = new MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0});
    const sphere = new Mesh(geometry, material);
    sphere.visible = false;
    sphere.position.set(this.origin.x, this.origin.y, this.origin.z);
    world.sceneController.AddToScene(sphere)


    world.sceneController.AddToScene(this.instance);
    world.animationLoop.AddAnimationLoop((time: number, clock: Clock, frame?: XRFrame | undefined) => this.AnimationCallback(time, frame));


    // watch(() => store.state.currentStage,
    //   async (stage) => {
    //     console.log("stage watcher stage:" , stage)
    //   })

  }

  Setup(fn: (stage: Stage) => void) {
    fn(this);
    this.CheckState(this.store.state.currentStage);

    watch(() => this.store.state.currentStage, async (newStage) => {
      this.CheckState(newStage)
    })
  }

  CheckState(newStage: any) {
    if (newStage == null) {
      return
    }

    if (newStage.Index == this.id && !this.isActiveScene) {
      this.OnEnter();
    } else if (newStage.Index != this.id && this.isActiveScene) {
      this.OnExit();
    }
  }

  AnimationCallback(time: number, frame?: XRFrame | undefined): void {
    this.OnUpdate(this);
  }

  OnUpdate(that: Stage): void {
    const distance = this.origin.distanceTo(this.store.state.world.camera.GetPosition());

    if(distance > this.activationDistance && this.instance.visible){
      
      this.OnChangeVisiblity(false);
    }
    else if (distance < this.activationDistance && distance > this.deactivationDistance &&  !this.instance.visible) {
      this.OnChangeVisiblity(true);
    }
    //Deactivate when far away
    else if (distance < this.deactivationDistance && this.instance.visible) {
      this.OnChangeVisiblity(false);
    }
  }

  OnChangeVisiblity(visible:boolean){
    this.instance.visible = visible;
    this.OnChangeStageVisiblity(visible);
  }

  OnChangeStageVisiblity(visible:boolean){ }

  OnEnter(): void {
    this.isActiveScene = true;    
    this.OnEnterStage();

    if(this.store.state.currentStage != null && Object.prototype.hasOwnProperty.call(this.store.state.currentStage, "StageCameraSettings") && this.store.state.currentStage.StageCameraSettings != null){
      this.store.state.world.camera.instance.far = this.store.state.currentStage.StageCameraSettings.far;
      this.store.state.world.camera.instance.near = this.store.state.currentStage.StageCameraSettings.near;
      this.store.state.world.camera.instance.updateProjectionMatrix();

      //console.log("Set Camera Settings" , this.store.state.world.camera.instance.near,this.store.state.world.camera.instance.far);

    }
    
    //console.log("OnEnter" , this.id);
  }
  OnExit(): void {
    
    if(this.isActiveScene){
      this.OnExitStage();
    }
    this.isActiveScene = false;
  }
}