import { Vector2 } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import IWorld from '../World/IWorld';
import { IPass } from './IPass';

class BloomEffect implements IPass{
  
  composer:EffectComposer
  pass: UnrealBloomPass

  constructor(composer:EffectComposer, world:IWorld){

    this.composer = composer;

    this.pass = new UnrealBloomPass( new Vector2(512,512), .94,.9,.5 );
    this.pass.threshold = .95;
    this.pass.strength = 2.9;
    this.pass.radius = .2;

		this.composer.addPass( this.pass );
		
  }

  Resize = () =>{

  }

}

export { BloomEffect }