import mainConfig from "../../main.config";
import { watch } from "vue";
import { useStore } from "vuex";
import IWorld from "./Setup/World/IWorld";
import NeurocureWorld from "./NeurocureWorld";
import { useRoute, useRouter } from "vue-router";
import { ScrollTo } from "@/common/Utils";
import { lerp } from "three/src/math/MathUtils";

export default class ScrollController {
  world: NeurocureWorld
  store: any
  route: any
  router: any
  threshold: number = window.innerHeight * .35
  scrollThresholdOffset: number = window.innerHeight * .16

  supportPageOffset: boolean
  isCSS1Compat: boolean

  pageY: number = 0
  enabled: boolean = true

  fromStage: null
  toStage: null

  devScrollContainer: any[] = []

  contentBlockIdsInOrder: string[] = []

  blockPositions: any

  constructor(world: NeurocureWorld) {
    this.store = useStore();
    this.route = useRoute();
    this.router = useRouter();
    this.world = world;

    this.blockPositions = [];

    this.supportPageOffset = window.pageXOffset !== undefined;
    this.isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
    this.world.animationLoop.AddAnimationLoop(this.CheckScrollPos);



    watch(() => this.store.state.blockPositions, (positions) => {
      this.UpdateBlockPositions();
    });

  }

  UpdateBlockPositions = () => {
    this.blockPositions = this.store.state.blockPositions;
    if (mainConfig.development) {

      if (this.devScrollContainer.length > 0) {
        this.devScrollContainer.map((element, id) => {
          element.parentNode.removeChild(element);
        });
        this.devScrollContainer = [];
      }

      Object.keys(this.store.state.blockPositions).map(id => this.DevContentBlock(id, this.store.state.blockPositions[id]));
    }

    this.contentBlockIdsInOrder = Object.keys(this.store.state.blockPositions).sort((a, b) => { return this.store.state.blockPositions[a] - this.store.state.blockPositions[b] })

    if (this.store.state.currentStage == null && typeof (this.route.params.id) != "undefined") {
      ScrollTo(this.store.state.blockPositions[this.route.params.id], true);
    }
  }

  Easing(value: number): number {
    return value < 0.5 ? 2 * value * value : 1 - Math.pow(-2 * value + 2, 2) / 2;
  }

  CheckScrollPos = () => {
    if (this.blockPositions == null || !this.enabled) { return; }


    //Gets actual page Offset Y
    this.pageY = this.supportPageOffset ? window.pageYOffset : this.isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

    this.pageY += this.scrollThresholdOffset;

    this.pageY = Math.floor(this.pageY);

    //Suche den ContentBlock zu dem gerade gescrollt wird => next
    const BlockInScrollPosition = Object.keys(this.blockPositions).find((blockID) => {
      const blockPosition = this.blockPositions[blockID];
      return this.isInBetween(this.pageY, blockPosition - this.threshold, blockPosition + this.threshold)
    });

    // Innerhalb der TransitionZone
    if (typeof (BlockInScrollPosition) != "undefined") {
      const indexOfCurrentBlockInScrollPosition = this.contentBlockIdsInOrder.indexOf(BlockInScrollPosition);

      if (indexOfCurrentBlockInScrollPosition != -1) {
        const fromContentBlockID = indexOfCurrentBlockInScrollPosition > 0 ? this.contentBlockIdsInOrder[indexOfCurrentBlockInScrollPosition - 1] : this.contentBlockIdsInOrder[0];
        const toContentBlockID = BlockInScrollPosition;

        //fromContentBlockID = fromContentBlockID == null ? toContentBlockID : fromContentBlockID;

        if (fromContentBlockID != null) {

          const fromContentBlock = this.ExtractContentBlockFromStageById(fromContentBlockID);
          const toContentBlock:any = this.ExtractContentBlockFromStageById(toContentBlockID);

          const minY = this.store.state.blockPositions[BlockInScrollPosition] - this.threshold;
          const maxY = this.store.state.blockPositions[BlockInScrollPosition] + this.threshold;

          //Calculate Percentage between blocks
          const range = maxY - minY;
          let stagePercentage = (this.pageY - minY) / range;

          stagePercentage = this.Easing(stagePercentage);

          //Prevent not reaching scrollMax und scrollMin
          //weil man zu schnell gescrollt hat
          // stagePercentage = stagePercentage < .005 ? 0 : stagePercentage;
          // stagePercentage = stagePercentage > .991 ? 1 : stagePercentage;

          if (fromContentBlock != null && toContentBlock != null && !this.store.state.devOptions.devChangesPerspective) {

            //Transition fromStage to toStage
            this.world.cameraTransition.LerpCameraPosition(fromContentBlock, toContentBlock, stagePercentage);
            //this.SetClippingPlanes(fromContentBlock, toContentBlock, stagePercentage);

            //Change Settings in route und store nur wenn fromStage to toStage anders sind als vorher
            if (this.fromStage != fromContentBlock || this.toStage != toContentBlock) {

              this.fromStage = fromContentBlock;
              this.toStage = toContentBlock;

              this.store.commit("SetTransitionObject", {
                currentBlock: fromContentBlock,
                nextBlock: toContentBlock,
                alpha: stagePercentage
              });

              if (this.store.state.currentStage == null && this.store.state.stages != null) {
                this.store.commit("SetCurrentStage", this.ExtractStageFromContentBlock(toContentBlockID));
              }

              if (this.route.params.id != toContentBlockID) {
                const stage:any = this.store.state.stages.find((s:any) => s.id == toContentBlock.stage);
                const stageURLHandle:any = stage.urlhandle;
                //console.log(stageURLHandle == null, stageURLHandle , toContentBlock, this.toStage, this.store.state.stages.find((s:any) => s.id == toContentBlock.stage));
                this.router.replace({ params: { id: toContentBlockID, title: toContentBlock.urlhandle, stagename: stageURLHandle } });
                this.store.commit("SetCurrentStage", this.ExtractStageFromContentBlock(toContentBlockID));
              }
            }
          }
        }
      }
    }
  }

  SetClippingPlanes(fromContentBlock: { stage: { id: any } }, toContentBlock: { stage: { id: any } }, alpha: number) {


    if (fromContentBlock.stage == undefined || toContentBlock.stage == undefined || this.store.state.currentStage == null) { return; }
    if (fromContentBlock.stage == toContentBlock.stage) { return; }

    
    if (this.store.state.lastStage == null) {
      this.world.camera.instance.far = this.store.state.currentStage.StageCameraSettings.far;
      this.world.camera.instance.near = this.store.state.currentStage.StageCameraSettings.near;
      this.store.state.world.camera.instance.updateProjectionMatrix();
      //console.log("LAST is null CURRENT ",this.store.state.currentStage.StageCameraSettings.near)

      return;
    }
    this.world.camera.instance.far = lerp(this.store.state.lastStage.StageCameraSettings.far, this.store.state.currentStage.StageCameraSettings.far, alpha);
    this.world.camera.instance.near = lerp(this.store.state.lastStage.StageCameraSettings.near, this.store.state.currentStage.StageCameraSettings.near, alpha);
    this.store.state.world.camera.instance.updateProjectionMatrix();
    //console.log("LAST ",this.store.state.lastStage.StageCameraSettings.near, " CURRENT ",this.store.state.currentStage.StageCameraSettings.near)

  }


  ExtractStageFromContentBlock(id: string) {
    let filteredStage = null;
    this.store.state.stages.map((stage: any) => {
      const filtered = stage.content_blocks.find((contentBlock: any) => {
        return contentBlock.id == id
      });

      if (typeof (filtered) != "undefined") {
        filteredStage = stage;
      }
    });
    return filteredStage;
  }


  ExtractContentBlockFromStageById(id: string) {

    let filteredContentBlock = null;
    this.store.state.stages.map((stage: any) => {
      const filtered = stage.content_blocks.find((contentBlock: any) => {
        return contentBlock.id == id
      });

      if (typeof (filtered) != "undefined") {
        filteredContentBlock = filtered;
      }
    });

    return filteredContentBlock;
  }

  DevContentBlock(id: string, posY: number) {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.width = "20px";
    div.style.height = this.threshold * 2 + "px";
    div.style.backgroundColor = "#ccc";
    div.style.right = "0px";
    div.style.top = (posY - this.scrollThresholdOffset - this.threshold) + "px";
    div.style.zIndex = "9999";
    div.innerHTML = id;

    this.devScrollContainer.push(div);

    document.body.appendChild(div);
  }

  isInBetween(value: number, a: number, b: number) {
    const min = Math.min.apply(Math, [a, b])
    const max = Math.max.apply(Math, [a, b])
    return value > min && value < max;
  }

}