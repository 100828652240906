import NeurocureWorld from "./NeurocureWorld";
import brain_complete from './gltf/brain_complete_cleaned_up_50m.glb';
import brain_landing from './gltf/brain_landing.glb';
import neuron_complete from './gltf/neuron_closed_100m.glb';
import neuron_offen_lowpoly from './gltf/neuron_open_100m.glb';
import campus from './gltf/berlin_campus_200m.glb';
import earth from './gltf/earth_100m.glb';
import cluster from './gltf/cluster.glb';
import cluster_neuronen from './gltf/cluster_neuronen.glb';

import { useStore } from "vuex";

class NeurocureLoader{
  meshesToLoad = {
    earth : {
      url : earth
    },
    campus : {
      url : campus
    },
    brain_landing : {
      url : brain_landing
    },
    brain_complete : {
      url : brain_complete
    },
    neuron_offen_lowpoly:{
      url: neuron_offen_lowpoly
    },
    neuron_complete : {
      url : neuron_complete
    },
    cluster_people : {
      url : cluster
    },
    cluster_neuronen : {
      url : cluster_neuronen
    }
  }

  world : NeurocureWorld
  store: any

  constructor(world:NeurocureWorld){
    this.world = world;
    this.store = useStore()
  }

  LoadProgress = (total:number , progress:any) => {
    this.store.commit("SetLoadingProgress",total);
  }

  Load():Promise<any>{
    return this.world.meshLoader.LoadMultiple(this.meshesToLoad, this.LoadProgress).then((lib)=>{
      this.store.commit("SetLibrary" , lib);
      return lib;
    });
  }
}


export default NeurocureLoader;