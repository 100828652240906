import IR_BasicWebGLRenderer from './Setup/Renderer/IR_BasicWebGLRenderer';
import {IR_SceneController} from './Setup/SceneController/IR_SceneController';
import IR_AnimationLoop from './Setup/AnimationLoop/IR_AnimationLoop';
import {
  Group,
  SpotLightShadow,
  PerspectiveCamera,
  PCFSoftShadowMap,
  AmbientLight,
  SpotLight,
  DirectionalLight,
  BoxGeometry,
  Color,
  Line,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  MeshNormalMaterial,
  MeshStandardMaterial,
  Scene,
  ShaderMaterial,
  Vector3,
  WebGLRenderTarget,
  PlaneBufferGeometry,
  CanvasTexture,
  AdditiveBlending,
  ReinhardToneMapping,
  sRGBEncoding,
  ACESFilmicToneMapping
} from 'three';

import Stage from './Stage';
import IWorld from './Setup/World/IWorld';
import IR_OrbitControls from './Setup/Controls/IR_OrbitControls';
import IR_PerspectiveCamera from './Setup/Camera/IR_PerspectiveCamera';
import MeshLoader from './Setup/MeshLoader/IR_MeshLoader';
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader';

import CameraTransition from './CameraTransition';
import {Store, useStore} from 'vuex';
import IAnimationLoop from './Setup/AnimationLoop/IAnimationLoop';
import ScrollController from './ScrollController';
import {Composer} from './Setup/Effects/EffectComposer';
import {DistortionEffect} from './Setup/Effects/DistortionEffect';
import {FXAAEffect} from './Setup/Effects/FXAAEffect';
import {SMAAEffect} from './Setup/Effects/SMAAEffect';
import {IPass} from './Setup/Effects/IPass';
import {IMeshLoader} from './Setup/MeshLoader/IMeshLoader';
import NeurocureLoader from './NeurocureLoader';
import {watch} from 'vue';
import {BokehEffect} from './Setup/Effects/BokehEffect';
import {BloomEffect} from './Setup/Effects/BloomEffect';
import {GradientEffect} from './Setup/Effects/GradientEffect';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {useRoute, useRouter} from 'vue-router';

import TWEEN, {Tween} from '@tweenjs/tween.js';
import LineController from './LineController';
import PeopleLineController from './PeopleLineController';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass';

import {BlendShader} from 'three/examples/jsm/shaders/BlendShader';
import {SavePass} from 'three/examples/jsm/postprocessing/SavePass.js';
import {CopyShader} from 'three/examples/jsm/shaders/CopyShader.js';
import MaterialLibrary from './MaterialLibrary';
import StageController from './StageController';
import PostProcessingController from './PostProcessingController'
import {ColorGradingEffect} from './Setup/Effects/ColorGradingEffect';
import mainConfig from './../../main.config';

/**
 * DEv
 */
declare global {
  interface Window {
    _world: any;
  }
}

window._world = window._world || {};


export default class NeurocureWorld implements IWorld {

  camera = new IR_PerspectiveCamera();
  sceneController = new IR_SceneController();
  postProcessingController = new PostProcessingController();
  renderer;
  controls;
  animationLoop : IAnimationLoop;
  composer : Composer;

  // PostEffects
  distortionEffect : DistortionEffect
  // bokehEffect: BokehEffect
  bloomEffect : BloomEffect;
  colorGradingEffect : ColorGradingEffect;
  gradientEffect : GradientEffect;
  antialiasEffect : IPass;
  savePass : SavePass;
  blendPass : ShaderPass;
  outputPass : ShaderPass;
  meshLoader : IMeshLoader = new MeshLoader();

  stages : {
  [key: string]: Stage;
  } = {}

  cameraTransition : CameraTransition;
  store : any;
  route : any;
  router : any;
  scrollController : ScrollController;
  neurocureLoader : NeurocureLoader;
  lineController : LineController | null = null;
  peopleLineController : PeopleLineController | null = null;
  stageController : StageController = new StageController();

  FresnelShaderColor : any;
  FresnelShaderColorDark : any;
  FresnelShaderColorBlue : any;
  FresnelShaderAlpha : any;
  FresnelShaderFloor : any;

  constructor(id : string) {

    window._world = this;

    this.camera.instance.near = .1;
    this.camera.instance.far = 100000;
    this.camera.instance.rotation.order = 'YXZ';

    this.store = useStore();
    this.route = useRoute();
    this.router = useRouter();

    this.renderer = new IR_BasicWebGLRenderer(id);
    this.renderer.instance.shadowMap.autoUpdate = false;
    this.renderer.instance.domElement.classList.add("hidden");

    this.renderer.instance.outputEncoding = sRGBEncoding;
    this.renderer.instance.toneMappingExposure = 2;
    this.renderer.instance.toneMapping = ReinhardToneMapping;

    // this.renderer.instance.shadowMap.enabled = true;
    // this.renderer.instance.shadowMap.type = PCFSoftShadowMap;

    this.composer = new Composer(this);

    // Smear Effect
    this.savePass = new SavePass(new WebGLRenderTarget(window.innerWidth, window.innerHeight, {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      stencilBuffer: false
    }));

    this.blendPass = new ShaderPass(BlendShader, 'tDiffuse1');
    this.blendPass.uniforms['tDiffuse2'].value = this.savePass.renderTarget.texture;
    this.blendPass.uniforms.mixRatio.value = 0.92;

    this.outputPass = new ShaderPass(CopyShader);

    this.composer.composer.addPass(this.blendPass);
    this.composer.composer.addPass(this.savePass);
    this.composer.composer.addPass(this.outputPass);

    this.distortionEffect = new DistortionEffect(this.composer.composer, this.camera.instance);

    this.antialiasEffect = new SMAAEffect(this.composer.composer);

    this.bloomEffect = new BloomEffect(this.composer.composer, this);

    // this.bokehEffect = new BokehEffect(this.composer.composer, this);
    this.colorGradingEffect = new ColorGradingEffect(this.composer.composer, this);
    this.gradientEffect = new GradientEffect(this.composer.composer, this);

    // this.bokehEffect.pass.renderToScreen = true;


    this.sceneController.AddToScene(new AmbientLight(0xeeeeee, 1));

    this.controls = new IR_OrbitControls(this.camera, this.renderer);

    this.controls.instance.enabled = mainConfig.development;

    this.animationLoop = new IR_AnimationLoop(this.renderer);

    this.cameraTransition = new CameraTransition(this.controls);

    this.animationLoop.AddAnimationLoop(this.AnimationLoop);

    this.scrollController = new ScrollController(this);

    this.neurocureLoader = new NeurocureLoader(this);

    this.controls.SetPosition(new Vector3(0, 22500, -17000));
    this.controls.SetTarget(new Vector3(0, 0, 0));
    this.sceneController.AddToScene(this.camera.instance);

    // Lights
    const light = new SpotLight(0xcccccc, 0.6, 100);

    light.position.set(0, 7, -5);
    light.rotation.set(-40 * Math.PI / 180, -40 * Math.PI / 180, 0);


    this.sceneController.AddToScene(light);


    // Load SceneMeshes
    this.neurocureLoader.Load().then(lib => {

      this.Setup(lib);

      this.sceneController.AnimateBackground(this.route.name);


      this.renderer.instance.domElement.classList.remove("hidden");
      this.renderer.instance.domElement.classList.add("fadeIn");

    });

    this.router.beforeEach((to : any, from : any, next : any) => {

      if (from.name == "MainPageStart" && to.name == "LandingPage") {
        this.router.push({name: 'MainPageStart'})
      }

      if (from.name == "LandingPage" && to.name == "MainPageStart") {
        this.store.state.world.postProcessingController.AnimatePostProcessSettings("Intro", 6.5);
        //console.log("Animation", this.cameraTransition.StartAnimation);

        this.cameraTransition.StartAnimation().then(() => {
          next();
        });
        return;
      }

      next();
    });

    if (this.store.state.world != null) {
      this.store.state.world.sceneController.AnimateBackground(this.route.name);
    }

  }

  PrepareLibrary = (library : any) => {


    this.lineController = new LineController(library.brain_landing.gltf.scene.children[0], this);

    /*library.cluster_people.gltf.scene.children[0].children.forEach((cluster: any) => {

      const people = cluster.children.filter((child: any) => {
        return child.name.includes("_person")
      })
      this.peopleLineController = new PeopleLineController(this, people, library.campus.gltf.scene);
    });*/


    /*library.cluster_neuronen.gltf.scene.children.forEach((cluster: any) => {

      const neurons = cluster.children.filter((child: any) => {
        return child.name.includes("neuron")
      })
      console.log("Neuron Cluster", neurons)
      //const neuronLineController = new PeopleLineController(this, neurons, library.neuron_offen_lowpoly.gltf.scene, 0.000001);
    });*/

    library.brain_landing.gltf.scene.traverse((child : any) => {
      if (child.type == "Mesh") {
        child.material = MaterialLibrary.brain_landing;
      }
    });

  }

  Setup = (library : any) => {
    this.PrepareLibrary(library);

    this.stageController.Setup(library).then(() => {
      this.store.commit("SetSceneReady", true);
    });
  }

  DegreeToRadians(deg : number) {
    return deg * Math.PI / 180;
  }

  AtmosphereCanvas() {
    const canvas = document.createElement("canvas");
    canvas.width = 256;
    canvas.height = 256;

    canvas.style.width = "256px";
    canvas.style.height = "256px";
    canvas.style.display = "none";
    canvas.style.position = "absolute";

    document.body.appendChild(canvas);

    const c = canvas.getContext("2d");
    // @ts-ignore: Object is possibly 'null'.
    const grd = c.createRadialGradient(128, 128, 5, 128, 128, 128);
    grd.addColorStop(0, "rgba(255,255,255,0)");
    grd.addColorStop(0.75, "rgba(255,255,255,0)");
    grd.addColorStop(0.85, "rgba(255,255,255,1)");
    grd.addColorStop(.95, "rgba(33,150,243,.1)");
    grd.addColorStop(1, "rgba(33,150,243,0)");
    // @ts-ignore: Object is possibly 'null'.
    c.fillStyle = grd;
    // @ts-ignore: Object is possibly 'null'.
    c.fillRect(0, 0, 256, 256);
    return new CanvasTexture(canvas);

  }

  AnimationLoop = (time : number) => {


    this.composer.composer.render();

    if (typeof(MaterialLibrary.brain_landing) != "undefined") {

      const speed = .001;
      const multiplier = .2;
      MaterialLibrary.brain_landing.uniforms.offsetPosition.value.set(Math.sin(time * speed) * multiplier, 0, Math.cos(time * speed) * multiplier);
      MaterialLibrary.brain_landing.uniformsNeedUpdate = true;
    }

    TWEEN.update();
  }
}
