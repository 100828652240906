import { Color, Uniform , Vector2, Vector3} from 'three';

const ColorGradingShader = {

	uniforms: {

		'tDiffuse': { value: null },
		'contrast': { value: 1.0 },
		'brightness': { value: 0.0 },
		'grayscale': { value: 1.0 },
		'grayscaleLevel': { value: new Color(0.21,0.71, 0.07) },

	},

	vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader:
    `
		precision highp float;

		uniform sampler2D tDiffuse;
    uniform float contrast;
    uniform float brightness;
    uniform float grayscale;
    uniform vec3 grayscaleLevel;

		varying vec2 vUv;

    vec3 adjustSaturation(vec3 color, float value) {
      float grey = dot(color.rgb, grayscaleLevel.rgb);
      vec3 grayscaleColor = vec3(grey);
      return mix(grayscaleColor, color, 1.0 + value);
    }

		void main() {
			gl_FragColor = texture2D(tDiffuse, vUv);

      gl_FragColor.rgb = adjustSaturation(gl_FragColor.rgb , grayscale);
      gl_FragColor.rgb = (gl_FragColor.rgb - 0.5) * contrast + 0.5 + brightness;

		}`

};

export { ColorGradingShader };