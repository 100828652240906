import { Group } from "three";
import { useStore } from "vuex"
import { Easing, Tween } from "@tweenjs/tween.js";
import { V_ON_WITH_MODIFIERS } from "@vue/compiler-dom";

class PostProcessingController {
    store: any
    library: any
    stages: any = {}

    mainPageSettings: any = {}
    landingPageSettings: any = {}
    introSettings: any = {}

    currentSettings: any = {}

    constructor() {
        this.store = useStore();

        this.landingPageSettings = {
            DepthOfFieldFocus: 1,
            DepthOfFieldAperture: 0.025,
            DepthOfFieldMaxblur: 0,

            //Smear: 0.000039571618668908425,
            Smear: 0.85,

            BloomRadius: 0,
            BloomStrength: 2.0,
            BloomThreshold: 0.359,

            ColorGradingBrightness: 0,
            ColorGradingContrast: 1,
            ColorGradingGrayscale: 0.19565217391304346,
            ColorGradingGrayscaleLevelR: 53,
            ColorGradingGrayscaleLevelG: 25,
            ColorGradingGrayscaleLevelB: 118,

            GradientBlendingColor_1R: 0,
            GradientBlendingColor_1G: 0,
            GradientBlendingColor_1B: 0,

            GradientBlendingColor_2R: 0,
            GradientBlendingColor_2G: 0,
            GradientBlendingColor_2B: 0,

            GradientBlendingOpacity: 0,
            GradientBlendingBlendOpacity: 0.55,
            GradientBlendingBlendModeIndex: 17
        }

        this.introSettings = {
            DepthOfFieldFocus: 1,
            DepthOfFieldAperture: 0.025,
            DepthOfFieldMaxblur: 0,

            //Smear: 0.000039571618668908425,
            Smear: 0.85,

            BloomRadius: 0,
            BloomStrength: 2,
            BloomThreshold: 0.75,

            ColorGradingBrightness: 0,
            ColorGradingContrast: 1,
            ColorGradingGrayscale: 0.19565217391304346,
            ColorGradingGrayscaleLevelR: 53,
            ColorGradingGrayscaleLevelG: 25,
            ColorGradingGrayscaleLevelB: 118,

            GradientBlendingColor_1R: 0,
            GradientBlendingColor_1G: 0,
            GradientBlendingColor_1B: 0,

            GradientBlendingColor_2R: 0,
            GradientBlendingColor_2G: 0,
            GradientBlendingColor_2B: 0,

            GradientBlendingOpacity: 0,
            GradientBlendingBlendOpacity: 0, //0.55
            GradientBlendingBlendModeIndex: 17
        }

        this.mainPageSettings = {
            DepthOfFieldFocus: 1,
            DepthOfFieldAperture: 0.025,
            DepthOfFieldMaxblur: 0,

            Smear: 0.2,

            BloomRadius: 0,
            BloomStrength: 0,
            BloomThreshold: 1,

            ColorGradingBrightness: 0.17,
            ColorGradingContrast: 1,
            ColorGradingGrayscale: 0.19565217391304346,
            ColorGradingGrayscaleLevelR: 53,
            ColorGradingGrayscaleLevelG: 25,
            ColorGradingGrayscaleLevelB: 118,

            GradientBlendingColor_1R: 255,
            GradientBlendingColor_1G: 255,
            GradientBlendingColor_1B: 255,//{ r: 22, g: 119, b: 114 },//16777215,

            GradientBlendingColor_2R: 126,
            GradientBlendingColor_2G: 166,
            GradientBlendingColor_2B: 204,

            GradientBlendingOpacity: 0,
            GradientBlendingBlendOpacity: 0.9,
            GradientBlendingBlendModeIndex: 17
        }

        this.currentSettings = this.landingPageSettings;
    }

    AnimatePostProcessSettings(settings: String, transitionTime: number) {
        switch (settings) {           
            case 'Landing':
                this.StartTransition(this.landingPageSettings, 0, true, true)
                this.currentSettings = this.landingPageSettings;
                //console.log("PP: Landing");
                break;
            case 'Intro':
                this.StartTransition(this.introSettings, transitionTime, true, true)
                this.currentSettings = this.introSettings;
                //console.log("PP: Landing");
                break;
            case 'MainPage':
                this.StartTransition(this.mainPageSettings, transitionTime, true, true)
                //console.log("PP: MainPage");
                break;
            default:
                this.StartTransition(this.mainPageSettings, 0, true, true)
                this.currentSettings = this.mainPageSettings;
                //console.log("PP: Default");
                break;
        }
    }


    StartTransition(newSettings: any, transitionTime: number, animateBloom: boolean, animateOverlay:boolean) {
        const tween = new Tween(this.currentSettings)
            .to(
                newSettings
                , transitionTime * 1000)
            .easing(Easing.Quadratic.InOut)
            .onUpdate((v) => {
                if(animateOverlay){this.SetOverlayValues(v)}
                if(animateBloom){this.SetBloomValues(v)}
            }).onComplete(() => {
                this.currentSettings = newSettings;
            });
        tween.start();
    }

    SetBloomValues(v: any) {
        //BLOOM
        this.store.state.world.bloomEffect.pass.threshold = v.BloomThreshold
        this.store.state.world.bloomEffect.pass.strength = v.BloomStrength
        this.store.state.world.bloomEffect.pass.radius = v.BloomRadius
    }

    SetOverlayValues(v: any) {
        //console.log("SET VALUES",v.GradientBlendingColor_1R)

        //Depht of Field
        /*this.store.state.world.bokehEffect.pass.uniforms.focus.value = v.DepthOfFieldFocus;
        this.store.state.world.bokehEffect.pass.uniforms.aperture.value = v.DepthOfFieldAperture;
        this.store.state.world.bokehEffect.pass.uniforms.maxblur.value = v.DepthOfFieldMaxblur;*/
        //SMEAR
        this.store.state.world.blendPass.uniforms.mixRatio.value = v.Smear;

        //COLOR GRADIENT
        this.store.state.world.colorGradingEffect.pass.uniforms.brightness.value = v.ColorGradingBrightness;
        this.store.state.world.colorGradingEffect.pass.uniforms.contrast.value = v.ColorGradingContrast;
        this.store.state.world.colorGradingEffect.pass.uniforms.grayscale.value = v.ColorGradingGrayscale;
        this.store.state.world.colorGradingEffect.pass.uniforms.grayscaleLevel.value.setRGB(v.ColorGradingGrayscaleLevelR / 255, v.ColorGradingGrayscaleLevelG / 255, v.ColorGradingGrayscaleLevelB / 255);

        //GRADIENT BLENDING
        this.store.state.world.gradientEffect.pass.uniforms.gradientColor_1.value.setRGB(v.GradientBlendingColor_1R / 255, v.GradientBlendingColor_1G / 255, v.GradientBlendingColor_1B / 255);
        this.store.state.world.gradientEffect.pass.uniforms.gradientColor_2.value.setRGB(v.GradientBlendingColor_2R / 255, v.GradientBlendingColor_2G / 255, v.GradientBlendingColor_2B / 255);
        this.store.state.world.gradientEffect.pass.uniforms.opacity.value = v.GradientBlendingOpacity;
        this.store.state.world.gradientEffect.pass.uniforms.blendModeIndex.value = v.GradientBlendingBlendModeIndex;
        this.store.state.world.gradientEffect.pass.uniforms.blendOpacity.value = v.GradientBlendingBlendOpacity;


    }
}
export default PostProcessingController